import React, { Component } from "react";
import Footer from "../../components/Footer/Footer";
// import { getUserActiveSubscription } from "../../store/selectors/subscription";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Appbar from "../../components/AppBarComponents/Appbar";
import { FirebaseApp } from "../../FirebaseApp";



var config = require("../../assets/config.json");
function Alert(props) {
  return <MuiAlert  variant="filled" severity={props.NotificationType === "error" ? "info" : "success"} {...props} />;
}
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifyUser: false,
      NotificationText: "",
    };
    this.firebaseApp = new FirebaseApp();
    this.firebaseApp.defaultTrace();
  }
  logoutUser = () => {
    this.props.onLogout();
    window.location.href = "/";
  };

  state = {
    // isActiveFreeTrial: false,
    // isExpiredFreeTrial: false,
    // otherSubs: false,
  };
  // handel back button or navigate on page to any page search box should hide
  componentWillMount() {
    this.handelHistory = ()=>{}
    // this.handelHistory = this.props.history.listen((location) => {
    //   this.props.showSearch && this.checkOnSearchPage(location) === false && this.props.handelSearchBox();
    // });
  }
  componentWillUnmount() {
    this.handelHistory();
  }
  checkOnSearchPage(location){
    return location.pathname.includes('/search/');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.notifyUser !== this.props.notifyUser) {
      this.setState({
        notifyUser: this.props.notifyUser,
        NotificationText: this.props.NotificationText,
      });

    }
  }


  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.CloseNotification()
    this.setState({ "notifyUser": false });
  };
  getClassName = () =>{
    if(config?.ssoLogin === true){
      return "mainHolder mainHolder-oauth"
    }
    else if (config?.isThirdPartyEnabled === true && config?.msalSigninLink === true){
      return "mainHolder mainHolder-sso-support"
    }
    return "mainHolder";
  }
  showFooter = () => {
    return (config.embeddedConfig?.isEmbedded ? config.embeddedConfig?.showFooter : true)
  }
  render() {
    // snackbar alert
    return (<div className="th-black">
      <div>
        {this.state.notifyUser ? (
          //  {/* <Notification NotificationText={this.state.NotificationText} /> */}
          <Snackbar open={true} autoHideDuration={3000} onClose={this.handleClose}> 
            <Alert NotificationType={this.props.NotificationType} style={{
              borderRadius: "20px",
              backgroundColor: config.secondaryColor,
              marginBottom: "40px"
            }}>
              {this.state.NotificationText}
            </Alert>
          </Snackbar>
        ) : (
            <div />
          )
        }
        {

          <Appbar
            isAuthenticated={this.props.isAuthenticated}
            setSearchQuery={this.props.setSearchQuery}
            handelSearchBox={this.props.handelSearchBox}
            pageFrom={this.props.pageFrom}
            showSearch={this.props.showSearch}
            globalSearchLoading={this.props.globalSearchLoading}
          />

        }

        <main
          style={{
            // width: window.screen.width < 1368 ? "100%" : "1210px",
            margin: "auto",
           
          }}
         className= {this.getClassName()}
        >
          {/* {config.canUnsubcribedUsersLogin == false && this.props.isSubscriptionActive == false ?
            <CustomDialog
              open={true}
              title={Translate({ id: "Subscription.UpgradeMembership" })}
              heading={Translate({ id: "Subscription.UpgradeMessage" })}
            >
              <div className="text-center padding16">
                <Button size="small" type="submit" color="secondary"
                  className="button btn-default" variant="contained"
                  onClick={this.resetSubscriptionStatus}
                  style={{ marginTop: "60px" }}
                >
                  <Typography variant="h5">
                    {Translate({ id: "Subscription.Ok" })}
                  </Typography>
                </Button>
              </div>
            </CustomDialog>
            : null} */}
          {this.props.children}
        </main>

      </div >

      {
        (!config.embeddedConfig || this.showFooter()) ?
        <div className="footerText" role="contentinfo">
          <Footer role="contentinfo" clientName={localStorage.getItem("ClientDisplayName")} />
        </div>:< div className="ewfooterSpace"/>
      }
      </div >
    );
  }
}

export default Layout;