import React from "react";
import ChannelsCardWrapper from "./ChannelsCardWarpper";
import { Grid } from "@material-ui/core";
import { Translate } from "utils/Translate";
// import Routes from "components/Router/Routes";
import config from 'assets/config.json';
import IconRigntArrow from 'CommonImages/icon-rightArrow'
import { useNavigate } from "react-router";

const ChannelsHome = (props) => {
  const navigate = useNavigate();

 const viewDetails = () => {
    navigate("/channels", {state: { clubClasses: props.clubClasses }} );
  };
 
    return (
       <div >       
          <div className="m-t-60 ">
                <div className='m-b-32 m-b-15'>
                    <div className="align-left d-flex makeTextClickable m-b-7" onClick={viewDetails} role='button' aria-label='Channel details' tabIndex='0'>
                        <div className="align-left font-h1 "> {Translate({ id: "homePage.ChannelsTitle" })} </div>
                        <IconRigntArrow id='arrow-5' color={config.primaryColor} cls={"icon-arrow-home"} />
                    </div>
                    <div className="text-gray font-h6 align-left "  >
                    {Translate({id:"homePage.ChannelsDescription"})}
                    </div>
                </div>    
         </div>
          <div className="mobileScroll m-t-15">      
          {<div className={`list`}>{props.channelData.map(
              (channelData, index) =>
                -index < 3 && (<ChannelsCardWrapper data={channelData} cardType="channel" />)
              )}          
          </div>}
          </div>
          </div>        
    );
}

export default ChannelsHome;
