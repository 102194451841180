import * as actionTypes from "./actionTypes";
import api from "../../api";
import * as APIConstants from "../APIConstants";
import {
  HTTPS_OK
} from "../../responseCodeConstant";
import { showErrorMessage } from "../../components/ErrorMessage";
import { setTenantConfigEventTime } from "./webPlayer";
import { MAX_API_ATTEMPTS, SiteSourceType } from "utils/constants";
 
let config = require("../../assets/config.json");
const commonConfig = require("../../assets/commonConfig.json");

const handleSuccessfulResponse = (dispatch,response) => {
  dispatch(updateTenantConfig(response));
  const tenantEntitlement = response;
  dispatch({
    type: actionTypes.FETCH_TENANT_ENTITLEMENT_SUCCESS,
    tenantEntitlement: tenantEntitlement,
  });
}
const handleErrorInResponse = (dispatch,response,attempt) => {
  if (attempt < MAX_API_ATTEMPTS) {
    dispatch(fetchTenantConfig(attempt));
  } else {
    dispatch({
      type: actionTypes.FETCH_TENANT_ENTITLEMENT_FAIL,
      tenantEntitlement: null,
      errorMsg: showErrorMessage(response),
    });
  }
}

 
export const fetchTenantConfig = (attempt = 0) => {
  try {
    const tenantId = config.name;
    return (dispatch) => {
      let url = APIConstants.getTenantEntitlementAPI;
      dispatch({
        type: actionTypes.FETCH_TENANT_ENTITLEMENT_START,
      });
      let body = {
        tenantId: tenantId,
      };

      api.post(url, body).then((response) => {
        if (response.status === HTTPS_OK) {
          handleSuccessfulResponse(dispatch, response.data[0]);

        } else {
          attempt = attempt + 1;
          handleErrorInResponse(dispatch,response,attempt);
        }
      }
      );
    };
  } catch (err) {
    console.log(err);
  }
};
 
export const updateTenantConfig = (response) => {
  return (dispatch) => {
    const configStartTime = new Date().getTime();
    let newConfig = response;
    let updatedConfig = { ...commonConfig, ...config };
    Object.keys(updatedConfig).forEach((key) => {
      config[key] = updatedConfig[key];
    });
    if (newConfig != undefined) {
      config["tenantID"] = newConfig.tenantID;
      config["contactEmailAddress"] = newConfig.contactEmailAddress;
    if (
      newConfig.siteSourceType != null &&
      newConfig.siteSourceType.toLowerCase() === SiteSourceType.FAC
      ) {
      config["AccessCodeMandatory"] = true;
      config["showReActivatebuttonForFAC"] = true;
    }
      config["language"] = newConfig.languageTag;
      config["supportedLanguages"] = newConfig.languageCodes;
      config["url"] = newConfig.url;
      config["currency"] = ""; // need to be discuss
      config["providerId"] = newConfig.providerId;
      config["logoURL"] = newConfig.logoURL;
      if (newConfig.mmsSignupLink !== null) {
        config["mmsSignupLink"] = newConfig.mmsSignupLink;
      }
      if (newConfig.ssoRedirectURI !== null || newConfig.ssoRedirectURI !== undefined) {
        config.msalSigninLink = newConfig.ssoRedirectURI;
      }
      config["azureTenantID"] = newConfig.azureTenantID;
      config["copyright"] = newConfig.copyright;
      config["jwJSPlayerId"] = newConfig.jwJSPlayerId;
      config["deleteAccountURL"] = newConfig.deleteAccountURL;
      config["publishableKey"] = newConfig.publishableKey;
      config["pricingTableId"] = newConfig.pricingTableId;
      config["localytics"] = newConfig?.localytics?.url;
      config["appTitle"] = newConfig.appTitle;
      config["numberOfDaysToShowNewOnWorkoutCard"] =
        newConfig.numberOfDaysToShowNewOnWorkoutCard;
      config["numberOfMySubscriptionItemsOnHomePage"] =
        newConfig.numberOfMySubscriptionItemsOnHomePage;
      config["siteSourceType"] = newConfig.siteSourceType;
      config["isMarketingConsent"] = newConfig.isMarketingConsent;
      config["skipUserSubscriptionCheck"] = newConfig.skipUserSubscriptionCheck;
      config["isSchedule"] = newConfig.isSchedule;
      config["landinPageGetAccessLink"] = newConfig.landinPageGetAccessLink;
      config["embeddedConfig"] = newConfig.embeddedConfig;
      config["lockedContentBehaviour"] = newConfig.lockedContentBehaviour;
      config["langauageSelector"] = newConfig.isLanguageSwitcher;
      config["logoImage"] = newConfig?.webImage?.light?.logo;
      config["heroImage"] = newConfig?.webImage?.light?.home;
      config["favIconImage"] = newConfig?.webImage?.light?.favIcon;
      config["ssoLogin"] = newConfig.oauthLogin;
      config["canUnsubcribedUsersLogin"] = newConfig.canUnsubcribedUsersLogin;
      config["hideTncAccepted"] = newConfig.hideTncAccepted;
      config["magicLink"] = newConfig.magicLink;
      config["thirdPartyPasswordResetUrl"] = newConfig.thirdPartyPasswordResetUrl;
      config["isSubscriptionApiCallSkip"] = newConfig.isSkipSubscription;
      config["subscriptionSource"] = newConfig.subscriptionSource;
      config["UpgradeRedirect"] = newConfig.membershipUpgradeUrl;
      if (newConfig.share != undefined && newConfig.share != null) {
        config["dynamicLinkDomain"] = newConfig.share.dynamicLinkDomain;
        config["dynamicLinkAllowedOnApp"] =
          newConfig.share.dynamicLinkAllowedOnApp;
        config["appStoreId"] = newConfig.share.appStoreId;
        config["androidAppBundleId"] = newConfig.share.androidAppBundleId;
        config["iosAppBundleId"] = newConfig.share.iosAppBundleId;
      }
      if (newConfig.webColor != undefined && newConfig.webColor != null) {
        config["primaryColor"] = newConfig.webColor.light?.primaryColor;
        config["accentColor"] = newConfig.webColor?.light?.accentColor;
        document.documentElement.style.setProperty(
          "--main-primary-color",
          config.primaryColor
        );
        document.documentElement.style.setProperty(
          "--hover-color",
          config.accentColor
        );
      }
      if (
        newConfig.siteSourceType != null &&
        newConfig.siteSourceType.toLowerCase() === SiteSourceType.SSO
      ) {
        config["isThirdPartyEnabled"] = true;
      } else {
        config["isThirdPartyEnabled"] = false;
      }
      config["shareEnabled"] = checkSharedEnabled(newConfig.entitlement);
    }
    
    if (config?.appTitle) {
      let title = config?.appTitle;
      if (title && document.getElementById("title")) {
        document.getElementById("title").innerHTML = title;
      }
    }
    dispatch({
      type: actionTypes.TENENT_CONFIG_LOADING_SUCCESS,
      tenantConfig: true,
    });
    const configEndTime = new Date().getTime();
    const time = Math.abs((configStartTime - configEndTime) / 1000);
    dispatch(setTenantConfigEventTime(time));
  };
};
 
export const checkSharedEnabled = (entitlement) => {
  let shareEnabled = false;
  Object.keys(entitlement).forEach((key) => {
    if (entitlement[key] !== undefined && entitlement[key] !== null) {
      Object.keys(entitlement[key]).forEach((innerKey) => {
        let value = entitlement[key][innerKey];
        if (value.toLowerCase() === "share") shareEnabled = true;
      });
    }
  });
  return shareEnabled;
};