
import { isInternetConnected} from "../../utils/utilityfunctions";
import {
  fitRadioRoyaltyApiCall,
  fitRadioRoyaltyNextApiCall,
} from "../../store/actions/fitRadioRoyalty";
import { store } from "../../store";
//import { dispatchFitRadioRoyaltyCall } from "../FitRadio/FitRadio";
// const FitRadioRoyaltyRequest = {
//   tenantId: string
//   trackId: number
//   playedat: number
//   sourcestream: string
//   sourcedetail: string
//   os: string
//   device: string
//   vendor: number
//   country: string
// }
class FitRadioRoyaltyCacheManager {
   isRunning;
   static instance;
   royaltyItems;
  constructor() {
    this.isRunning = false
    this.royaltyItems = []
  }

  getToken = () => {
    return new Promise((resolve, _reject) => {
       resolve(localStorage.getItem('token'))
    })
  }

  async isUserLoggedIn() {
    const token = await this.getToken()
    return token ? true : false
  }

  setRoyaltyQueueRunning(running) {
    this.isRunning = running
  }

  get isRoyaltyQueueRunning() {
    return this.isRunning
  }

  isNextRoyaltyItem() {
    return this.royaltyItems?.length > 0
  }

  getNextRoyaltyItem() {
    return this.royaltyItems.splice(0, 1)[0]
  }

  newRoyaltyItemAdded(item) {
    if (this.isRunning) {
      this.royaltyItems.push(item)
    } else {
      this.run()
    }
  }

  static getInstance() {
    if (!FitRadioRoyaltyCacheManager.instance) {
      FitRadioRoyaltyCacheManager.instance = new FitRadioRoyaltyCacheManager()
    }
    return FitRadioRoyaltyCacheManager.instance
  }

  async run(){
    if (this.isRunning) {
      return
    }
    this.isRunning = true
    this.royaltyItems = store
      .getState()
      .fitRadioRoyalty.pendingRoyaltyCalls && store
      .getState()
      .fitRadioRoyalty.pendingRoyaltyCalls.slice()
    while (
      (await isInternetConnected()) &&
      (await this.isUserLoggedIn()) &&
      this.isNextRoyaltyItem()
    ) {
      const nextRoyaltyItem = this.getNextRoyaltyItem()
      store.dispatch(fitRadioRoyaltyNextApiCall(nextRoyaltyItem));
    }
    this.isRunning = false
  }

  async callFitRadioRoyaltyApi(
    royaltyItem,
    isFirstTime,
  ){
   store.dispatch(fitRadioRoyaltyApiCall(royaltyItem, isFirstTime));
   if(isFirstTime){
     this.addPendingCallToStore(royaltyItem);
   }
  }

  addPendingCallToStore(royaltyItem) {
    if (this.isRunning) {
      this.royaltyItems.push(royaltyItem)
    }
  }

  async execute(royaltyItem) {
    this.callFitRadioRoyaltyApi(royaltyItem, true)
  }
}

export default FitRadioRoyaltyCacheManager