import React, { useEffect, useState } from 'react';
import ClassOfTheComponent from './ClassOfTheDayComponent'
import Routes from '../../Router/Routes'
import config from "../../../assets/config.json"
import logger from "../../../utils/logger"
import { useNavigate } from 'react-router';


function Cotd(props) {
    const [classOfTheDay, setClassOfTheDay] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (Object.keys(props.cotdObj).length > 0) {
            if (props.cotdObj.collectionType === "classoftheday" &&
             (props.cotdObj.collectionItems != null) && 
             (props.cotdObj.collectionItems.length > 0)) {
                logger(props.cotdObj);
                setClassOfTheDay(props.cotdObj);
            }
        }

    }, [props.cotdObj]);
    const takeUserToClassDetail = (index) => {
            navigate(Routes.onDemandClassPlayerRoute + classOfTheDay.collectionItems[index].tag,
                    {state: { selectedItem: classOfTheDay.collectionItems[index] } } );
    };
    
    const checkForCollectionItems = (classOfTheDay) => {      
        if (classOfTheDay && classOfTheDay?.collectionItems?.length > 0) {
            return true;
        }
        else return false
    }
    return (
        <React.Fragment>
            {checkForCollectionItems(classOfTheDay) &&
                    <div className="m-t-60 " > 
                        <ClassOfTheComponent classOfTheDay={classOfTheDay} cardType="cotd-card " list="cotd-list"  takeUserToClassDetail={takeUserToClassDetail}/>
                    </div>
            }

        </React.Fragment>
    )
}

export default Cotd;
