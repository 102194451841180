import React, { useEffect, useState } from "react";
import { Translate } from "utils/Translate";
import Slider from "hoc/SliderContainer";
import Routes from "components/Router/Routes";
import config from "assets/config.json";
import IconRigntArrow from "CommonImages/icon-rightArrow";
import { useNavigate } from "react-router";

function CollectionClasses(props) {
 
  const [collectionData, setcollectionData] = useState(props.collectionArray);

  const navigate = useNavigate();
  useEffect(() => {
    /**
     * Nikhil Gupta
     * CF-9190
     * to show the featured collection under collection tag on home page
     */

    let collectionArray = props.collectionArray;
    if (collectionArray) {
      let featuredCollection = collectionArray.find((collection) => {
        return collection.collectionType.toLowerCase() === "featured";
      });
      setcollectionData(featuredCollection);
    }
  }, [props.collectionArray]);

  const clickHandler = () => {
    navigate(Routes.collections);
  };

  return collectionData &&
    collectionData.collectionItems &&
    collectionData.collectionItems.length > 0 ? (
    <>
      <div className="m-t-60 ">
        <div className="m-b-25 m-b-15">
          <div
            data-testid={"collectionTitleClick"}
            className="align-left d-flex makeTextClickable m-b-7"
            onClick={clickHandler}
            role='button'
            aria-label='Collection'
            tabIndex='0'
          >
            <div
              className="align-left font-h1 "
              data-testid="homePageCollectionTitle"
            >
              {Translate({ id: "homePage.CollectionsTitle" })}
            </div>
            <IconRigntArrow id="arrow-1" color={config.primaryColor} cls={"icon-arrow-home"} />
          </div>
          <div className="text-gray font-h6 align-left ">
            {Translate({ id: "homePage.CollectionsDescription" })}
          </div>
          <div
            className="align-left d-flex makeTextClickable m-t-12 collection-caption-cont"
            data-testid="collection-card"
            onClick={() => {
              navigate(
                Routes.collectionViewRoute + collectionData.collectionTag,
                {
                  state: {
                    selectedCollection: collectionData.collectionName,
                  },
                }
              );
            }}
            role='button'
            aria-label='Collection'
            tabindex='0'
          >
            <div className=" collection-caption align-left makeTextClickable ">
              {collectionData.collectionName}
            </div>
            <IconRigntArrow id="arrow-2" color={config.primaryColor} cls={"icon-arrow-home"} />
          </div>
        </div>
      </div>
      <div className="CollectionHome mobileScroll m-t-8">
        {(
          <Slider
            key={Math.random()}
            collectionTag={collectionData.collectionTag}
            collectionName={collectionData.collectionName}
            items={collectionData.collectionItems}
            description={collectionData.description}
            listClass={"list"}
            fromHome={true}
            navigate={navigate}
          />
        )}
      </div>
    </>
  ) : null;
}

export default CollectionClasses;
