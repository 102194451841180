import ClasslistView from "../containers/PlayerAndCorousel/views/ClasslistView/ClasslistView";
import {
    fetchAllChannels,
  } from "../store/actions/channels"
  import { setClassesSearchData, setPaginatedData, resetFilters } from "../store/actions/filter"
  import { connect } from "react-redux";
  import {
    fetchOnDemandClasses,
    clearClassData,
    FetchClubLiveConnect,
    FetchLSConnectHome,
    fetchCotdHomePageData,
    resetBrowseClassData,
    resetFllOndemand,
    collectionListClasses,
    getFavouritedClasses
  } from "../store/actions/ondemand";
  // import { SendMarketingStatusFromMail, SendToastMsg } from "../store/actions/auth";
  import { GetUserSubscription } from "../store/actions/subscription";
  import { getUserActiveSubscription } from "../store/selectors/subscription";
  import {injectIntl} from 'react-intl'; 
  import config from "../assets/config";
  import { getAllSubscriptionBundleDetails } from "store/actions/subscriptionBundle";
import { getUserRecentActivity } from "store/actions/workout";
import { getPlayList } from "store/actions/playList";
import { homePageItemCount } from "utils/constants";

  const pageSize = homePageItemCount.items
  const pageNo = homePageItemCount.pageNo

  const mapStateToProps = state => {
    return {
      collection: state.onDemand.featuredAndManagedCollectionList,
      selectedOnDemandClass: state.onDemand.selectedOnDemandClass,
      classes: [],
      clubClasses: state.tenantConfig?.entitlement?.homePageLoggedIn.live ? state.onDemand.LSHomeClasses : state.onDemand.clubClasses,
      loading: state.onDemand.loading,
      liveConnectLoading: state.onDemand.liveConnectLoading,
      isAuthenticated: state.auth.token !== "",
      isNewUser: state.auth.userCreated,
      userActiveSubscription: getUserActiveSubscription(state),
      isSubscriptionLoading: state.subscription.isSubscriptionLoading,
      userSubscription: state.subscription.userSubscripton,
      fetchUserDetailsLoading: state.auth.fetchUserDetailsLoading,
      allChannels: state.channels.allChannels,
      cotdHomePageData: state.onDemand.cotdHomePageData,
      fllOnDemand: state.onDemand.fllOnDemand,
      cotdLoading: state.onDemand.cotdLoading,
      entitlementLoggedIn: state.tenantConfig.entitlement?.homePageLoggedIn,
      entitlementLoggedOut: state.tenantConfig.entitlement?.homePageLoggedOut,
      featuresConfig: state.tenantConfig.entitlementResponse?.features,
      mySubscription: state.subscriptionBundle?.subscriptionBundleDetails,
      mySubscriptionLoading: state.subscriptionBundle?.loading,
      isAllChannelsLoading: state.channels.isAllChannelsLoading,
      isMetadataLoading: state.onDemand.MetadataLoading,
      recentActivities: state.workout.recentActivities,
      favoriteClasses: state.onDemand?.favoriteClasses,
      playlistData: state.playList
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      onOnDemandClassesLoad: userId => dispatch(fetchOnDemandClasses(userId)),
      onCotdHomePageData: userId => dispatch(fetchCotdHomePageData(userId)),
      clearClassData: () => dispatch(clearClassData()),
      fetchClubLiveConnectClasses: () => dispatch(FetchClubLiveConnect()),
      loadUserSubscription: () => dispatch(GetUserSubscription()),
      FetchLSConnectHome: () => dispatch(FetchLSConnectHome()),
      fetchAllChannels: () => dispatch(fetchAllChannels()),
      fetchAllClassesForChannel: (tag) => dispatch(fetchAllClassesForChannel(tag)),
      resetBrowseClassData: () => dispatch(resetBrowseClassData()),
      setClassesSearchData: () => dispatch(setClassesSearchData([])),
      setPaginatedData: () => dispatch(setPaginatedData([])),
      resetFilters: () => dispatch(resetFilters()),
      resetFllOndemand: () => dispatch(resetFllOndemand()),
      getmysubscription: (pageSize) => dispatch(getAllSubscriptionBundleDetails(pageSize)),
      collectionListClasses: (data) => dispatch(collectionListClasses(data)),
      fetchFavouritedClasses: () => dispatch(getFavouritedClasses(pageNo, pageSize)),
      fetchRecentActivityClasses: () => dispatch(getUserRecentActivity(pageNo, pageSize)),
      fetchPlayList: (tenantID) => dispatch(getPlayList(tenantID, "playlist", pageNo, pageSize)),

    };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ClasslistView));
