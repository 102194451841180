import { useDispatch, useSelector } from "react-redux";
import { loadPoKeys } from "store/actions/ondemand";
import { GetSiteConfig } from "store/actions/siteConfig";
import { fetchTenantConfig } from "store/actions/tenantConfig";

const useTranslationWrapper = () => {
    const dispatch = useDispatch();
    const useTranslationWrapperstate = () => ({
         entitlementResponse : useSelector((state) => state.tenantConfig?.entitlementResponse),
         languageData : useSelector((state => state.onDemand?.languageData)),
         entitlementResponseStatus :  useSelector((state) => state.tenantConfig?.isEntiltlementLoading),
         languageDataStatus : useSelector((state => state.onDemand?.languageDataLoading))
    })

    const useTranslationWrapperDispatch = () => ({
        getSiteConfig : () => { dispatch(GetSiteConfig()) },
        getTenantConfig : () => { dispatch(fetchTenantConfig()) },
        getPoData : () => { dispatch(loadPoKeys()) }
    })
    return {useTranslationWrapperstate , useTranslationWrapperDispatch}
}
export {useTranslationWrapper}