import React, { useState } from "react";
import "../../components/PlayerAndCarousel/Workout/Workout.css";
import CountdownButton from "./countdownButton";
import { getNextOccurenceDate, getTimetoStart } from '../../utils/LivestreamTime';
import { appendQueryParameterInURL, getPosterSize } from '../../utils/utilityfunctions';
import Routes from "../../components/Router/Routes"
import { Translate } from "../../utils/Translate";
import { useNavigate } from "react-router";
import { LockIcon } from "CommonImages/lock-icon";
import config from "../../assets/config.json";
import { TenantLockedContentBehaviour } from "utils/constants";
import CustomDialog from "components/Modal/Modal";
import { Button, Typography } from "@material-ui/core";


function LiveStreamCard(props) {
  const [showPopup, setShowPopup] = useState(false)
  const navigate = useNavigate();
  const openPlayer = () => {
    navigate(`${Routes.liveSchedulePlayerRoute}${props.id}`,
      {
        state: {
          cardDetails: {
            activeTime: props.activeTime,
            creationDate: props.creationDate,
            description: props.description,
            duration: props.duration,
            eventStatus: props.eventStatus,
            id: props.id,
            level: props.level,
            nextOccurence: props.nextOccurence,
            thumbnail: props.thumbnail,
            title: props.title,
            trailerLinkWeb: props.trailerLinkWeb,
            //url: props.url,
            videoId: props.videoId,
            durationInSec: props.durationInSec,
            type: props.type
          }
        }
      });
  }
  const showLockIcon = () => {
    if (props.isUnlocked === true) {
      return false;
    }
    return true;
  };
  const cardType = "channel-page-card"
  const collectionType = "live"

  const okButtonClick = () => {
    setShowPopup(false)
    if (config.UpgradeRedirect != null) {
      window.open(config.UpgradeRedirect, "_blank");
    } 
  };
  const handleClick = () => {
    let token = localStorage.getItem("token")
    if (props.skipIsUnlockedCheck === true) {
      openPlayer();
    } else {
      if (!token) {
        navigate(Routes.signinPage)
      } else {
        if (props.isUnlocked === true) {
          openPlayer();
        }
        else if (props.isUnlocked === false) {
          const lockedBehaviour = config.lockedContentBehaviour
          switch (lockedBehaviour) {
            case TenantLockedContentBehaviour.show_popup:
              setShowPopup(true)
              break;
            case TenantLockedContentBehaviour.show_subscription_options:
              navigate(Routes.subscribeRoute)
              break;
            case TenantLockedContentBehaviour.do_nothing:
              //do nothing
              break;
            default:
              //do nothing
              break;
          }
        }
      }
    }
  };
  return (<>
    {showPopup ? (
      <CustomDialog
        open={true}
        title={Translate({ id: "Subscription.UpgradeMembership" })}
        heading={Translate({ id: "Subscription.UpgradeMessage" })}
        aria-label='Subscription'
      >
        <div className="text-center padding16">
          <Button
            size="small"
            type="submit"
            color="secondary"
            className="button btn-default"
            variant="contained"
            onClick={okButtonClick}
            style={{ marginTop: "60px" }}
            role='button'
            aria-label='Subscription'
            tabIndex='0'
          >
            <Typography variant="h5" tabIndex='0' role='button' aria-label='Subscription'>
              {Translate({ id: "Subscription.Ok" })}
            </Typography>
          </Button>
        </div>
      </CustomDialog>
    ) : null}

    <div data-testid={"cardClick"} className={` ${cardType} makeTextClickable`} onClick={handleClick} role='button' aria-label='Live stream' tabIndex='0'><div className={`cardPos comman`} >
      <div className={`imageHolder ${cardType !== 'cotd-card' ? "full-dark-overlay" : "cotd-overlay"}`} style={{ backgroundImage: `url(${appendQueryParameterInURL(props.thumbnail, "width", getPosterSize(cardType).cardWidth)})` }} >
        <div className="contentTop">
          <div className="timeholder">
            {collectionType === "live" && <div className="d-flex" tabIndex='0'>
              <span tabIndex='0' class="live-title m-b-8 live-title-truncate">{props.title}</span>
              {props.type !== "VOD" && <span tabIndex='0' className="live-label livecard-label m-b-8">{Translate({ id: "liveStream.Live" })}</span>}
            </div>}
            {collectionType !== "featured" && <span tabIndex='0' class={`classname ${cardType === 'cotd-card' ? "cotd-date" : "m-b-5 "}`}>{getNextOccurenceDate(props.eventStatus, props.activeTime, props.nextOccurence)}</span>}
            {collectionType === "live" && <span class="live-time" tabIndex='0'>{getTimetoStart(props.nextOccurence, props.activeTime)}</span>}
            {showLockIcon() && (
              <LockIcon color={"#fff"} classes={"livestream-lock-icon"} />
            )}
          </div>
        </div>
      </div>
      {collectionType === "live" && <CountdownButton startTime={props.activeTime} occurenceDate={props.nextOccurence} isStreaming={props.eventStatus} onClick={openPlayer} aria-label='Live stream counter button' role='button' tabIndex='0' />}
    </div>
    </div>
  </>
  )
}

export default LiveStreamCard
