import React, { useRef } from 'react';
import { Grid, Typography } from "@material-ui/core";
import { Translate } from "../../utils/Translate";
import { useNavigate } from 'react-router';
import { appendQueryParameterInURL, getPosterSize } from 'utils/utilityfunctions';

const config = require("../../assets/config.json");


const Logo = (props) => {
    const navigate = useNavigate();

    const logoImageUrl =() => {
       return config.logoImage ? appendQueryParameterInURL(config.logoImage, "width", getPosterSize("").logoWidth) : "";
    }

    const showLogo = () => {
        return (config.embeddedConfig?.isEmbedded ? config.embeddedConfig?.showLogo : true)
    }

    return (
    <div  onClick={() => {

        if(!(config.embeddedConfig &&config.embeddedConfig["content-mode"] &&config.embeddedConfig["content-mode"].toLowerCase() === "tenant")){
            navigate( "/" );
        } else{
            navigate("/connect/clublist");
        }
    }}
        className="logoHolder" role='button'  aria-label='Logo' tabindex='0'>
        {
            (!config.embeddedConfig || showLogo())?
            <React.Fragment>
                    <img tabindex="0" className="logoImg" src={logoImageUrl()}  alt="Logo" role='button'  aria-label='Logo'/>
            </React.Fragment>:<React.Fragment>
            <Grid
              item
              className="makeTextClickable"
            >
              <Typography className="logo-name align-left" style={{color:config.primaryColor}}>
                {Translate({ id: "footer.home" })}
              </Typography>
            </Grid>
            </React.Fragment>
        }


    </div>
);}

export default Logo;