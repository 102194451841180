import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
const config = require('../../assets/config.json')
import theme from "../../theme/theme";
import { MuiThemeProvider } from "@material-ui/core";

const Spinner = (props)=> {
    const { areCards } = props;

    return (
        <div style={{ textAlign: "center" }} className="classes-spinner">
            <MuiThemeProvider theme={theme}>
            {!areCards && ( 
                <CircularProgress
                    label="loader"
                    aria-label="loader"
                    size={70}
                    thickness={5}
                    color="primary"
                />
            )}
            </MuiThemeProvider>
        </div>
    );
}

export default Spinner;
