import React from "react";
import Button from "@material-ui/core/Button";
import { Translate } from "../utils/Translate";
import AppBarButtonStyle from "../theme/styles/AppBarButtonStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Typography } from "@material-ui/core";
import AccountButtonDotsMobile from "../components/AccountButtonDotsMobile";
import { isEmbeddedMode } from "../utils/utilityfunctions";
var config = require("../assets/config.json");

class AccountButtons extends React.Component {

  homePageLoggedOutEntitlement = this.props.homePageLoggedOutEntitlement
  
  hideSignUpButton() {
    if (this.homePageLoggedOutEntitlement?.signup === false || isEmbeddedMode()) {
      return true
    }
    else {
      return false
    }
  }
  hideLoginButton() {
    if (this.homePageLoggedOutEntitlement?.signin === false || isEmbeddedMode()) {
      return true
    }
    else {
      return false
    }
  }
  onClickHandlerSignUp = () => {
    if(config.mmsSignupLink  === null || config.mmsSignupLink  === undefined){
      this.props.navigate("/signup")
    }
    else{
      window.open(config.mmsSignupLink, '_blank');
    }
  }
  onClickHandlerSignIn = () => {
    if(config.msalSigninLink  === null || config.msalSigninLink  === undefined){
      this.props.navigate("/signin")
    }
    else{
      window.open(config.msalSigninLink,"_self");
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="loginButtonHolder">
          <Grid container justifyContent="flex-end" style={{ height: "inherit", alignItems: "center" }}>
            {!this.homePageLoggedOutEntitlement?.subscription ? (<div></div>) : (
              <Grid item
                variant="contained"
                data-testid={"Subscriptions-id"}
                className={this.props.classes.AccountBarButton}
                style={
                  {
                    // ...FontStyle.SFProDisplaySmall,
                    paddingRight: "16px",
                    cursor: "pointer"
                  }}
                onClick={this.onClickHandler} 
                role='button'
                aria-label='Subscription'
                tabindex='0'
              >               
                <Typography variant="button" role='button' aria-label='Subscription'>{Translate({ id: "ClassListViews.Subscriptions", defaultMessage: "Subscriptions", })}</Typography>
              </Grid>)}
          {this.hideSignUpButton() ? (<div></div>) : (
              <Grid item
                variant="contained"
                // color="secondary"
                className={this.props.classes.AccountBarButton}
                style={
                  {
                    // ...FontStyle.SFProDisplaySmall,
                    paddingRight: "16px",
                    cursor: "pointer"
                  }}
                data-testid={"SignUp-id"}
                onClick={this.onClickHandlerSignUp}
                role='button'
                aria-label='SignUp'
                tabindex='0'
              >
                <Typography variant="button"  tabindex="0" role='button' aria-label='SignUp'>{Translate({ id: "layout.SignUp" })}</Typography>
              </Grid>)}
              {this.hideLoginButton() ? (<div></div>) : (<Grid item>
            <Button className={`${this.props.classes.loginButton} btn-default`}
                style={{
                  // ...FontStyle.SFProDisplaySmall,                  
                }}
                color="secondary"
                variant="contained"
                data-testid={"signIn-id"}
                onClick={this.onClickHandlerSignIn}
                role='button'
                aria-label='SignIn'
                tabindex="0"
              >
                <Typography variant="button"  tabindex="0" role='button' aria-label='SignIn'>
                  {Translate({ id: "layout.SignIn" })}
                </Typography>
              </Button>
            </Grid>)}
          </Grid>
        </div>

        <div className="loginButtonHolderMobile pull-right">
          <Grid container justifyContent="flex-end">
            {this.hideSignUpButton() ? (<div></div>) : (<Grid item >
                <Typography style={{
                textTransform: 'none',
                width: '64px',
                height: '30px',
                padding: '4px 8px',
                marginTop: '6px'
              }} variant="h5"
                onClick={this.onClickHandlerSignUp} 
               aria-label='SignIn'  tabindex="0">
                  {Translate({ id: "Signup.Title" })}
                </Typography>
            </Grid>)}
            {this.hideLoginButton() ? (<div></div>) : (<Grid item>
              <Button style={{
                textTransform: 'none',
                width: '64px',
                height: '30px',
                borderRadius: '15px',
                padding: '4px 8px',
                marginTop: '6px'
              }}
                className="btn-default"
                color="secondary"
                variant="contained"
                onClick={this.onClickHandlerSignIn}
                role='button'
                aria-label='SignIn'
                tabindex="0"
              >
                <Typography variant="h5"  aria-label='SignIn' tabindex='0'> 
                  {Translate({ id: "layout.SignIn" })}
                </Typography>
              </Button>
            </Grid>)}
            <Grid item style={{ paddingTop: "7px" }}>
              {this.homePageLoggedOutEntitlement?.signup === false ? (<div></div>) :
                // <img
                //   className=""
                //   src={require("../images/more.svg")}
                //   onClick={() => {
                //     this.props.history.push({ pathname: "/options" });
                //   }}
                // />
                <AccountButtonDotsMobile
                  entitlement={this.homePageLoggedOutEntitlement}
                ></AccountButtonDotsMobile>
              }</Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(AppBarButtonStyle)(AccountButtons);

