import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import Localytics from "./hoc/LocalyticsContainer";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme/theme";
import { setAppLoadStartEvent } from "./store/actions/webPlayer";
import Router from "components/Router/Router";
import EmbeddedWrapper from "EmbeddedWrapper";
import { useRouter } from "hooks/useRouter";
import { GetSiteConfig } from "./store/actions/siteConfig.js";
import {RoyaltyCacheTrigger} from "components/FitRadio/RoyaltyCacheTrigger";
import { initLocalytics } from "./utils/Localytics";
const AppWrapper = (props) => {
  const [currentTheme, setCurrentTheme] = useState(theme);
  const { webPlayerStartTime, appLoadStartTime } = props;
  const getInitials = useCallback(() => {
    webPlayerStartTime(appLoadStartTime);
  }, [webPlayerStartTime, appLoadStartTime]);
  useEffect(() => {
    getInitials();
  }, [getInitials]);

  // Initialize Localytics once we have tenant config data
  useEffect(() => {
    if (props.tenantConfig != null) {
      initLocalytics()
    }
  }, [props.tenantConfig])

  const updatePalette = () => {
    const newThemeSettings = require('./assets/config.json');
    setCurrentTheme(prevTheme => createTheme({
      ...prevTheme,
      palette: {
        primary: {
          main: "#000"
        },
        secondary: {
          main: newThemeSettings.primaryColor || "#000"
        },
        error: {
          main: newThemeSettings.errorColor
        }
      }
    }));
  };

  useEffect(() => {
    updatePalette();
  }, []);

  const routerInfo = useRouter();

  return (
    <React.Fragment>
        {/* <Localytics> WDP-8657: Commenting this as its not required*/}
            <MuiThemeProvider theme={currentTheme}>
              <EmbeddedWrapper embededConfig={props.embededConfig}>
                <Router {...props} {...routerInfo} />
              </EmbeddedWrapper>
            </MuiThemeProvider>
          {/* </Localytics> */}
          <RoyaltyCacheTrigger />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantConfig: state.tenantConfig.tenantConfig,
    tenantEntitlement: state.tenantConfig.entitlement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    webPlayerStartTime: (appLoadStartTime) =>
      dispatch(setAppLoadStartEvent(appLoadStartTime))
  };
};

//export default App;
export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
