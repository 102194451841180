export const createThirdPartyUser = () => {
  return
};

export const AuthenticateThirdPartyUser = () => {
  return 
};

export const GetConsentDetail = () => {
  return
};

export const getThirdPartyUserSubscription = () => {
  return 
};

export const UpdateUserProfileData = data => {
  return 
};

export const GetUserConsent = () => {
  return 
};

export const GetUserDetail = () => {
  return 
};
