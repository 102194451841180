import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from "redux-persist/integration/react";
import App from "../AppWrapper";
import { flattenMessages } from 'utils/flattenMessages';
import config from "../assets/config.json"
import Spinner from './spinner/AppLoader';
import { GetConfigData, GetTranslationData } from 'store/actions/ClientSettings';
import { persistor } from 'store';
import { Translate } from 'utils/Translate';
import { useTranslationWrapper } from 'hooks/useTranslationWrapper';
import { changeFavicon } from 'utils/utilityfunctions';
import { applyConfigStyles, insertDynamicStyles } from 'theme/styles';


const TranslationWrapper = (props) => {

    const {useTranslationWrapperstate , useTranslationWrapperDispatch} = useTranslationWrapper();
    const {entitlementResponse, languageData, entitlementResponseStatus, languageDataStatus} = useTranslationWrapperstate();
    const {getSiteConfig, getTenantConfig, getPoData} = useTranslationWrapperDispatch();

    useEffect(() => {
      getSiteConfig();
      getTenantConfig();
      getPoData()
    }, [ ])

  
    const clientData = GetConfigData(); 
    const appLoadStartTime = new Date().getTime();
    const language = entitlementResponse?.languageTag ?? config.language;
    const message = GetTranslationData(clientData.name, language);

    const showLoader = () => {
        if ((entitlementResponseStatus && entitlementResponse == null) || (languageDataStatus && languageData == null)){
            return true
        }
    }

    // Apply configuration styles
    applyConfigStyles(config);

    insertDynamicStyles();
    
   // applying favicon
   const faviconUrl = config.favIconImage;
   changeFavicon(faviconUrl);
  
    return (<>
      {showLoader() ?
        <Spinner /> : null }

        <IntlProvider
          locale={"en"}
          messages={flattenMessages(message[language])}
          defaultLocale="en"
          defaultMessages={flattenMessages(message["en"])}
          onError={() => { }}
          >
          {entitlementResponse && languageData ?
          <BrowserRouter>
            <PersistGate persistor={persistor}>
              <App
                appLoadStartTime={appLoadStartTime}
                embededConfig={props.embededConfig}
              />
            </PersistGate>
          </BrowserRouter> :
          !showLoader() && 
          <div className="page-container initial-error">
               {Translate({id: 'ApiResponseStrings.GenericError'})}
          </div>
            
        }
        </IntlProvider> 
    </>);
  }

  export default TranslationWrapper