import { initializeApp } from "firebase/app";
import { getPerformance, trace } from "firebase/performance";

/// get json from file
const firebasePerfMonitoring = require("./firebase-perf-config.json");

class FirebaseApp {
  App;
  perfrence;
  traceData;
  constructor() {
    const firebaseConfig = this.getConfig();
    this.App = initializeApp(firebaseConfig);
  }
  getConfig() {
    try {
      const {
        apiKey,
        authDomain,
        databaseURL,
        projectId,
        storageBucket,
        messagingSenderId,
        appId,
        measurementId,
      } = firebasePerfMonitoring;
      return {
        apiKey,
        authDomain,
        databaseURL,
        projectId,
        storageBucket,
        messagingSenderId,
        appId,
        measurementId,
      };
    } catch (e) {
      return {
        apiKey: "",
        authDomain: "",
        databaseURL: "",
        projectId: "",
        storageBucket: "",
        messagingSenderId: "",
        appId: "",
        measurementId: "",
      };
    }
  }
  defaultTrace() {
    try {
      this.perfrence = getPerformance();
      this.traceData = trace(this.perfrence);
    } catch (e) {}
  }
  setUp(pageName = "home") {
    this.perfrence = getPerformance();
    this.traceData = trace(this.perfrence, pageName);
  }
  getDetails() {
    return { perfrence: this.perfrence, traceData: this.traceData };
  }
  setAttribute({ key, value }) {
    this.traceData.putAttribute(key, value);
  }
  getAttribute(key) {
    this.traceData.gutAttribute(key);
  }
  getAttributes() {
    this.traceData.getAttributes();
  }
  delAttribute(key) {
    this.traceData.removeAttribute(key);
  }
}

export { FirebaseApp };
