// styles/index.js
import "./index.scss";

const setCSSProperty = (property, value) => {
  document.documentElement.style.setProperty(property, value);
};

const applyConfigStyles = (config) => {
  setCSSProperty("--main-primary-color", config.primaryColor);
  setCSSProperty("--main-secondary-color", config.secondaryColor);
  setCSSProperty("--hover-secondary-color", config.secondaryColor);
  setCSSProperty("--hover-color", config.accentColor);
  setCSSProperty("--bg-theme-color", config.backgroundTheme);
  setCSSProperty("--bar-color", config.barColor);
  setCSSProperty("--footer-color", config.footerColor);
  setCSSProperty("--icon-color", config.secondaryAccentColor);
  setCSSProperty("--lipstick", config.newLabelBgColor);
};

const insertDynamicStyles = () => {
  const clientColor = localStorage.getItem("clientcolor");
  const style = document.createElement("style");
  if (clientColor) {    
    style.innerHTML = `
        .vjs-play-progress, .vjs-volume-level { background-color: ${clientColor} !important; }
        .dynamiclinks { color: ${clientColor} !important; }
      `;
    const ref = document.querySelector("script");
    ref?.parentNode?.insertBefore(style, ref);
  }

  // eslint-disable-next-line no-unused-vars
  var ref = document.querySelector("script");
  ref = ref?.parentNode?.insertBefore(style, ref);
};

export { setCSSProperty, applyConfigStyles, insertDynamicStyles };
