import React from "react";
import { createRoot } from 'react-dom/client';
// import ReactDOM from "react-dom";
import "./theme/styles/index.scss";
import registerServiceWorker from "./registerServiceWorker";
import { Provider } from "react-redux";
// import locale_en from "react-intl/locale-data/en";
// import locale_de from "react-intl/locale-data/de";
import { v4 as uuidv4 } from 'uuid';
import {changeFavicon, isEmptyOrNullString, isPseudoSso} from "./utils/utilityfunctions";
import {
  checkLocalStorage,
  GetConfigData,
} from "./store/actions/ClientSettings";
import UnsupportedBrowser from "./components/UnsupportedBrowser";
import { store } from "./store";
import { applyConfigStyles, insertDynamicStyles } from "./theme/styles";
import TranslationWrapper from "components/TranslationWrapper";



const addLocaleData = require("@formatjs/intl-pluralrules/polyfill");
const locale_de = require("@formatjs/intl-pluralrules/locale-data/de");
const locale_en = require("@formatjs/intl-pluralrules/locale-data/en");
const config = require("./assets/config.json");

registerServiceWorker();

const URL =
  window.location.search && window.location.search !== ""
    ? window.location.search
    : sessionStorage.getItem("embededConfig");

let embededConfig = {};
if (URL && URL !== "" && URL.includes("runtime=embedded")) {
  sessionStorage.setItem("embededConfig", URL);
  let queryParamsArray = URL.slice(1)
    .split("&")
    .map((str) => str.split("="));
  for (let i = queryParamsArray.length; i--; ) {
    embededConfig[queryParamsArray[i][0]] = queryParamsArray[i][1];
  }

  if (embededConfig["lang"]) {
    config["language"] = embededConfig["lang"];
  }
  let caseInsensitiveObject = {};
  for (let key in embededConfig) {
     caseInsensitiveObject[key.toLowerCase()] = embededConfig[key];
    }
  if (isPseudoSso() && config.generateUniqueUserId && isEmptyOrNullString(caseInsensitiveObject["userId".toLowerCase()])) {
    const existingUserId = localStorage.getItem("externalUserId");
    let userId = existingUserId ?? uuidv4();
    embededConfig["userId"] = userId;
    localStorage.setItem("externalUserId", embededConfig["userId"]);
    localStorage.setItem("ExternalUserId", embededConfig["userId"]);
  }
}
// addLocaleData([locale_en, locale_de]);

checkLocalStorage();
var clientData = GetConfigData();

/**
 * nikhil gupta
 * 22th june 2020
 * check for internet explorer
 * edge without chromium (Edge/)
 */
var ua = window.navigator.userAgent;
var isIE = /MSIE|Trident|Edge\//.test(ua);

const Index = ()=> {
  return (
    <React.Fragment>
      {isIE ? (
        <UnsupportedBrowser />
      ) : (
        <Provider store={store} >
            <TranslationWrapper embededConfig={embededConfig} />
        </Provider>
      )}
    </React.Fragment>
  );};
  if(document.getElementById("root"))
  {
  const root = createRoot(document.getElementById("root"));
  root.render(<Index/>);
  }
