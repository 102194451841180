/**
 * RR
 * Club Manage is part of assets/ClubManager folder
 * Same stucutre is followed on React-Native-Wexer-App
 */
// Nikhil --> Uncomment the code for SSO
// export * from "../assets/ClubManager/action";  
// module.exports = require('../assets/ClubManager/action'); 
try {
    const module = require('../assets/ClubManager/action');
    Object.keys(module).forEach((key) => {
        exports[key] = module[key];
    });
    console.log('Module successfully imported and re-exported.');
} catch (error) {
    const module = require('./action');
    Object.keys(module).forEach((key) => {
        exports[key] = module[key];
    });
    console.log('Dummy Module successfully imported and re-exported.');
}