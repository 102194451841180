import React from 'react'
import {Helmet} from "react-helmet";
import { getTenantSpecificConfigurations } from '../../utils/utilityfunctions';
const configJson = require("../../../src/assets/config.json")
const config = getTenantSpecificConfigurations(); 
const AddDependency = () => {
    let JWPLAYER = 'wUkkXJdm.js';
    if (config){
         JWPLAYER = config.JWPLAYER
    }
    const {jwJSPlayerId} = configJson;
    const cdnPlayer = `https://cdn.jwplayer.com/libraries/${jwJSPlayerId ?? JWPLAYER}`;
    return (
        <Helmet>
            <script src={cdnPlayer}></script>
        </Helmet>
    )
}

AddDependency.propTypes = {

}

export {AddDependency}
