import React, { useEffect, useState } from 'react';
import Workout from 'hoc/WorkoutContainer';
import { Translate } from 'utils/Translate'

const ClassOfTheDay = React.memo(function Cotd(props) {
    const { classOfTheDay } = props;
    const [temData, setTempData] = useState([])

    useEffect(() => {
        if (props?.classOfTheDay?.collectionItems?.length > 0) {
            let arr = []
            for (let index = 0; index < props.classOfTheDay.collectionItems.length; index++) {
                arr.push(props.classOfTheDay.collectionItems[index])
            }
            const newArr = arr.slice(0, 3);
            setTempData(newArr)
        }
    }, [props?.classOfTheDay?.collectionItems])
    return (
        <React.Fragment>
                <div className='m-b-32 m-b-15'>
                        <h1 data-testid="cotd-title" className="align-left font-h1 clear m-b-7">
                             {Translate({ id: "homePage.ClassOfTheDayTitle" })}
                        </h1>
                        <div data-testid="cotd-desc" className="text-gray font-h6 align-left "  >
                            {Translate({ id: "homePage.ClassOfTheDayDescription" })}
                        </div>
                </div>         
                <div data-testid={"cotdCards"} className={`list ${props.list}`}>
                     {temData.map((data,index) =>
                            <Workout
                                cardType={"cotd-card"}
                                collectionType={classOfTheDay.collectionType}
                                trailerLinkWeb={data.trailerLinkWeb}
                                trailerLinkMobile={data.trailerLinkMobile}
                                thumbnail={`${data.imageLink}`}
                                title={data.className}
                                duration={data.durationSecond
                                    ? Math.ceil(data.durationSecond / 60)
                                    : 55}
                                level={data.skill}
                                displayDate={data.displayDate}
                                show={true}
                                calories={data.calorieBurn}
                                id={data.tag}
                                classCategory={data.classCategory}
                                clicked={()=>props.takeUserToClassDetail(index)}
                                favDisabled
                                mediaType={data.mediaType}
                                isUnlocked={data.isUnlocked}
                            />)}
                        </div>

        </React.Fragment>
    );
});



export default ClassOfTheDay;

